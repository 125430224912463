<template>
  <div class="mideBotContainer flex-col">
    <div class="charHeder flex">
      <div
        class="hedaerItem flex-1 flex flex-center"
        @click="handHederChange(item)"
        :class="{ active: headerActive === item.value }"
        v-for="(item, index) in headerList"
        :key="index"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="charContainer">
      <lineChar :charData="charData"></lineChar>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import lineChar from "@/components/market/lineChar.vue";
const props = defineProps({
  charData: {
    type: Array,
    default: () => {
      return [];
    },
  },
});
const emit = defineEmits(["changeHeader"]);
const headerActive = ref("1");
const headerList = ref([
  {
    label: "1M",
    value: "1",
    type: '1min'
  },
  {
    label: "5M",
    value: "5",
    type: '5min'
  },
  {
    label: "15M",
    value: "15",
    type: '15min'

  },
  {
    label: "30M",
    value: "30",
    type: '30min'
  },
  {
    label: "1H",
    value: "60",
    type: '60min'
  },
  {
    label: "1D",
    value: "d",
    type: '1day'
  },
]);
const handHederChange = (val) => {
  headerActive.value = val.value;
  emit("changeHeader", val);
};
</script>

<style lang="scss" scoped>
.mideBotContainer {
  width: 100%;
  .charHeder {
    padding: 5px 0;
    width: 100%;
    .hedaerItem {
      height: 21px;
      font-size: 14px;
      border-radius: 5px;
      cursor: pointer;
      margin: 0 5px;
      &:hover {
        background-color: #5670f6;
        color: #fff;
      }
      &.active {
        background-color: #5670f6;
        color: #fff;
        border: none;
      }
    }
  }
  .charContainer {
    width: 100%;
    height: 500px;
  }
}
</style>
